function landing_anim(){
	let options = {
		threshold: 0.30,
	};

	let observer = new IntersectionObserver(entries => {
		// Loop over the entries
		entries.forEach(entry => {
			// If the element is visible
			if (entry.isIntersecting) {
				// Add the animation class
				entry.target.classList.add('anim_fadein');
			}
		});
	}, options);

	let features = document.getElementsByClassName("frontpage_feature");
	for(let i = 0; i < features.length; i++){
		observer.observe(features[i]);
		features[i].style.opacity = 0;
	}

	let stanim = document.getElementsByClassName("anim_fadein");
	for(let i = 0; i < stanim.length; i++){
		stanim[i].style.opacity = 0;
	}
}

landing_anim();